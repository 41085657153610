import React from "react";

import { ProgramSection } from "components/program-section";
import { programCategories } from "lib/programs";

import { Heading } from "../components/heading";

const HomePage: React.FC<React.PropsWithChildren<Readonly<unknown>>> = () => {
  return (
    <div className="max-w-5xl m-auto text-center">
      <div className="py-12">
        <Heading size={2} text="Apply to On Deck" className="mb-12" />
        {programCategories
          .filter((p) => p.programs.length > 0)
          .map((category) => (
            <ProgramSection category={category} key={category.slug} />
          ))}
      </div>
    </div>
  );
};

export default HomePage;
