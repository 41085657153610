import React from "react";

import { Button } from "components/button";
import { Heading } from "components/heading";
import { ProgramCategory } from "lib/programs";
import { getActiveCohorts } from "utils/cohorts";

interface CategorySectionProps {
  category: ProgramCategory;
}

export const ProgramSection: React.FC<React.PropsWithChildren<Readonly<CategorySectionProps>>> = ({ category }) => (
  <div className="mb-12 text-left">
    <Heading size={3} text={category.title} className="mb-8" />
    <p className="max-w-md mb-8">{category.description}</p>
    <ul className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
      {category.programs.map((program) => {
        const startDate = getActiveCohorts(program.cohorts)[0]?.startedAt
          ? new Date(getActiveCohorts(program.cohorts)[0]?.startedAt).toLocaleDateString("en-US", {
              year: "numeric",
              month: "short",
              day: "numeric",
            })
          : "TBD";
        const costsString = getActiveCohorts(program.cohorts)[0]?.cost
          ? new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            }).format(parseInt(getActiveCohorts(program.cohorts)[0]?.cost || ""))
          : "TBD";
        return (
          <li key={program.slug} className="col-span-1 bg-white divide-y divide-gray-200 rounded-lg shadow-lg">
            <div className="px-8 py-6">
              <Heading size={4} text={`${program.name}`} className="mb-4" />
              <p className="mb-6">{program.description}</p>
              <p className="">
                <b>Next start date:</b> {startDate}
              </p>
              <p className="mb-6">
                <b>Cost: </b>
                {/* TODO (richard): Store cost type in program */}
                {program.slug === "founders" ? costsString + " per year" : costsString}
              </p>
              <Button
                link={`/programs/${program.slug}`}
                label="Apply"
                // @ts-expect-error: This is erroring because we're passing the programs from program-categories without yup validation; that's OK as this page isn't live in prod
                program={program}
                eventName="applyButtonClick"
                internalLink
              />
            </div>
          </li>
        );
      })}
    </ul>
  </div>
);
