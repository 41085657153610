import {
  ApplyCohortOnlyGqlCodegen,
  ApplyFormOnlyGqlCodegen,
  ApplyProgramOnlyGqlCodegen,
  ApplyStepOnlyGqlCodegen,
} from "features/program-applications/utils";

import {
  ContactDetailsForm,
  DemographicsForm,
  EODApplicationForm,
  ODAApplicationForm,
  ODFNewApplicationForm,
  ODSApplicationForm,
} from "./forms";

const _programs: (ApplyProgramOnlyGqlCodegen & {
  cohorts: ApplyCohortOnlyGqlCodegen[];
  applySteps: (ApplyStepOnlyGqlCodegen & { form: ApplyFormOnlyGqlCodegen })[];
})[] = [
  {
    name: "On Deck Founders",
    shortTitle: "ODF",
    title: null,
    slug: "founders",
    description:
      "On Deck Founder Fellowship is a 1-year program that brings together a group of founders that are in the early days of exploring/starting their next company. Meet co-founders, early hires, raise capital, and accelerate through the most formative early days of building a company. To date, we’ve helped create 1000+ startups worth 9B+ in the last 2.5 years.",
    applySteps: [ContactDetailsForm, ODFNewApplicationForm, DemographicsForm],
    // use ODF as per recommendation by Steven/Rishi https://on-deck-internal.slack.com/archives/C01AVQ695HC/p1652883591174419?thread_ts=1652865794.677089&cid=C01AVQ695HC
    id: "ODF",
    opengraphImageUrl: null,
    cohorts: [
      {
        id: "",
        name: "ODF14",
        slug: "odf14",
        startedAt: "July 12, 2022",
        applicationsClosedAt: "2022-07-11T19:00:00.000Z",
        applicationsStartedAt: null,
        cost: "2990",
      },
      {
        id: "",
        name: "ODF15",
        slug: "odf15",
        startedAt: "September 13, 2022",
        applicationsClosedAt: "2022-09-27T19:00:00.000Z",
        applicationsStartedAt: "2022-07-11T19:00:00.000Z",
        cost: "2990",
      },
      {
        id: "",
        name: "ODF16",
        slug: "odf16",
        startedAt: "January 17, 2023",
        applicationsClosedAt: "2023-01-14T23:20:00.000Z",
        applicationsStartedAt: "2022-09-27T19:00:00.000Z",
        cost: "2990",
      },
      {
        id: "",
        name: "ODF17",
        slug: "odf17",
        startedAt: "April 21, 2023",
        applicationsClosedAt: "2023-04-11T06:59:00.000Z",
        applicationsStartedAt: "2023-01-14T23:20:00.000Z",
        cost: "2990",
      },
      {
        id: "",
        name: "ODF18",
        slug: "odf18",
        startedAt: "June 30, 2023",
        applicationsClosedAt: null,
        applicationsStartedAt: "2023-04-11T06:59:00.000Z",
        cost: "2990",
      },
    ],
  },
  {
    // Note(Zach W) this placeholder is needed for the ODX shoulder tap form
    name: "Founders Fellowship",
    title: null,
    shortTitle: "ODX",
    slug: "shoulder-tap",
    description: null,
    id: "",
    opengraphImageUrl: null,
    applySteps: [],

    cohorts: [
      {
        id: "",
        name: "ODF14",
        slug: "odf14",
        startedAt: null,
        applicationsClosedAt: null,
        applicationsStartedAt: null,
        cost: null,
      },
    ],
  },
  {
    name: "On Deck Scale Fellowship",
    title: null,
    shortTitle: "ODS",
    slug: "scale",
    description:
      "On Deck Scale (ODS) is a close, trusted community of founders leading high-growth, venture-scale companies from around the world. Our off-the-record events and curated network create a safe space for decision-making and developing frameworks that will help you scale — from seed-stage to IPO.",
    applySteps: [ContactDetailsForm, ODSApplicationForm, DemographicsForm],
    id: "",
    opengraphImageUrl: null,
    cohorts: [
      {
        id: "",
        name: "ODS4",
        slug: "ods4",
        startedAt: "September 25, 2022",
        applicationsClosedAt: "2022-07-07T19:00:00.000Z",
        applicationsStartedAt: null,
        cost: "10000",
      },
      {
        id: "",
        name: "ODS5",
        slug: "ods5",
        startedAt: "January 29, 2022",
        applicationsClosedAt: null,
        applicationsStartedAt: "2022-07-07T19:00:00.000Z",
        cost: "10000",
      },
    ],
  },
  {
    name: "Angels Fellowship",
    title: null,
    shortTitle: "ODA",
    slug: "angels",
    description:
      "A continuous community for operator angels. We combine an incredible network of peers, world-class curriculum and exclusive deal flow to help you refine your investing craft and build lasting relationships.",
    applySteps: [ContactDetailsForm, ODAApplicationForm, DemographicsForm],
    id: "",
    opengraphImageUrl: null,
    cohorts: [
      {
        id: "",
        name: "ODA7",
        slug: "oda7",
        startedAt: "June 12, 2022",
        applicationsStartedAt: null,
        applicationsClosedAt: "2022-07-15T11:00:00.000Z",
        cost: "5000",
      },
      {
        id: "",
        name: "ODA8",
        slug: "oda8",
        startedAt: "September 2022",
        applicationsClosedAt: "2022-10-19T10:30:00.000Z",
        applicationsStartedAt: "2022-07-15T11:00:00.000Z",
        cost: "5000",
      },
      {
        id: "",
        name: "ODA9",
        slug: "oda9",
        startedAt: "January 2023",
        applicationsClosedAt: null,
        applicationsStartedAt: "2022-10-19T10:30:00.000Z",
        cost: "5000",
      },
    ],
  },
  {
    name: "Execs On Deck",
    title: null,
    shortTitle: "EOD",
    slug: "execs",
    description:
      "Execs on Deck is a curated community for leaders with 12+ years of experience who want to build successful careers in the startup ecosystem.",
    applySteps: [ContactDetailsForm, EODApplicationForm, DemographicsForm],
    id: "",
    opengraphImageUrl: null,
    cohorts: [
      {
        id: "",
        name: "EOD3",
        slug: "eod3",
        startedAt: "February, 2023",
        applicationsStartedAt: "2022-10-25T11:00:00.000Z",
        applicationsClosedAt: "2023-03-10T10:00:00.000Z",
        cost: "4990",
      },
      {
        id: "",
        name: "EOD4",
        slug: "eod4",
        startedAt: "May 2023",
        applicationsStartedAt: "2023-03-10T10:00:00.000Z",
        applicationsClosedAt: "2023-06-30T10:00:00.000Z",
        cost: "4990",
      },
      {
        id: "",
        name: "EOD5",
        slug: "eod5",
        startedAt: "September 2023",
        applicationsStartedAt: "2023-06-30T10:00:00.000Z",
        applicationsClosedAt: null,
        cost: "4990",
      },
    ],
  },
];

// Make sure only active programs are used/exported
export const programs = _programs.filter((p) => !("odx" === p.slug || p.slug === "shoulder-tap"));

// TODO: Remove the programCategories as soon as we have removed the home page: https://linear.app/on-deck/issue/A2O-350/refactor-remove-page-applybeondeckcom
export interface ProgramCategory {
  title: string;
  description: string;
  slug: string;
  programs: (ApplyProgramOnlyGqlCodegen & {
    cohorts: ApplyCohortOnlyGqlCodegen[];
    applySteps: ApplyStepOnlyGqlCodegen[];
  })[];
}
export const programCategories: ProgramCategory[] = [
  {
    slug: "startups",
    title: "Startups",
    description:
      "Building a company is lonely. Find a home with the others just crazy enough to take the leap and join or back the rocket ships they're building.",
    programs: programs.filter((p) => ["founders", "scale"].includes(p.slug ?? "")),
  },
  {
    slug: "investors",
    title: "Investors",
    description:
      "There has never been a better time to deploy capital into ambitious talent. Join in investing in the future.",
    programs: programs.filter((p) => ["angels"].includes(p.slug ?? "")),
  },
  {
    slug: "executives",
    title: "Execs",
    description: "Where top Execs go to find what’s next.",
    programs: programs.filter((p) => ["execs"].includes(p.slug ?? "")),
  },
];
