import React from "react";

import cx from "classnames";
import Link from "next/link";
import { useRouter } from "next/router";

import rudderTyper from "@on-deck/shared/rudder-typer";
import { Loader } from "components/loader";
import type { ApplyCohortYup, ApplyProgramYup } from "features/program-applications/utils";
import { getCampaignData } from "utils/get-campaign-data";

export interface ButtonProps {
  action?: () => void;
  className?: string;
  disabled?: boolean;
  eventName?: string;
  eventPayload?: Record<string, unknown>;
  internalLink?: boolean;
  label: string | React.ReactNode;
  link?: string;
  loading?: boolean;
  size?: "xs" | "sm" | "md" | "lg" | "xl";
  target?: string;
  type?: "primary" | "secondary" | "green" | "red" | "gray";
  outline?: boolean;
  program?: ApplyProgramYup;
  cohort?: ApplyCohortYup;
}

type ButtonColor =
  | "primary"
  | "secondary"
  | "green"
  | "red"
  | "gray"
  | "primary-outline"
  | "green-outline"
  | "red-outline"
  | "gray-outline";

export const Button: React.FC<React.PropsWithChildren<Readonly<ButtonProps>>> = ({
  action,
  className = "",
  disabled,
  eventName = "buttonClick",
  internalLink,
  label,
  link,
  loading,
  size = "md",
  target,
  type = "primary",
  outline = false,
}) => {
  let elementName: string;
  disabled = disabled || loading;
  // Use a button if disabled because you can't disable an 'a' tag
  if (!(link || internalLink) || disabled) {
    elementName = "button";
  } else {
    elementName = "a";
  }
  const Element = `${elementName}` as keyof JSX.IntrinsicElements;

  const buttonColors: Record<ButtonColor, string> = {
    primary: "bg-od-blue text-white hover:bg-blue-500 disabled:bg-od-blue",
    secondary: "bg-transparent border-white text-white hover:bg-white hover:text-od-blue",
    green: "bg-green-600 text-white hover:bg-green-700 disabled:bg-green-600",
    red: "bg-red-500 text-white hover:bg-red-600 disabled:bg-red-500",
    gray: "bg-gray-500 text-white hover:bg-gray-600 disabled:bg-gray-500",
    "primary-outline": "border-2 border-od-blue text-od-blue hover:border-blue-500 disabled:border-od-blue",
    "green-outline": "border-2 border-green-600 text-green-600 hover:border-green-700 disabled:border-green-600",
    "red-outline": "border-2 border-red-500 text-gray-500 hover:border-red-600 disabled:border-red-500",
    "gray-outline": "border-2 border-gray-500 text-gray-500 hover:border-gray-600 disabled:border-gray-500",
  };

  const buttonSizes = {
    xs: "px-2.5 py-1.5 text-xs",
    sm: "px-3 py-2 text-sm",
    md: "px-6 py-3 text-base",
    lg: "px-6 py-3 text-lg",
    xl: "px-8 py-4 text-lg",
  };

  const baseClasses: string[] = [
    "focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",
    "disabled:opacity-50 disabled:cursor-not-allowed",
    "inline-flex items-center justify-center",
    "border border-transparent",
    "rounded-full shadow-sm",
    "font-bold",
    "transition duration-200 ease-in-out",
    className,
  ];
  const query = useRouter().query;
  const instanceProps = {
    onClick: () => {
      if (action) {
        action();
      }
      if (eventName === "continueButtonClick") {
        void rudderTyper.applicationContinued(
          {
            program_cohort: typeof query?.cohortSlug === "string" ? query.cohortSlug : "unknown",
            program_slug: typeof query?.programSlug === "string" ? query.programSlug : "unknown",
          },
          { context: { campaign: getCampaignData() } },
        );
      }
      void rudderTyper.buttonClicked(
        {
          button_text: typeof label === "string" ? label : "",
          program_cohort: typeof query?.cohortSlug === "string" ? query.cohortSlug : "unknown",
          program_slug: typeof query?.programSlug === "string" ? query.programSlug : "unknown",
        },
        { context: { campaign: getCampaignData() } },
      );
    },
    className: cx(baseClasses, buttonSizes[size], buttonColors[outline ? (`${type}-outline` as ButtonColor) : type]),
    disabled,
    target,
  };

  if (internalLink && link) {
    return (
      <Link href={link} legacyBehavior>
        <Element {...instanceProps}>{label}</Element>
      </Link>
    );
  }

  return (
    <Element href={link} {...instanceProps}>
      {loading && elementName === "button" ? <Loader size="xs" color="text-white w-24" /> : label}
    </Element>
  );
};
