import React from "react";

import cx from "classnames";

interface HeadingProps {
  text: string;
  size?: number;
  semanticSize?: number;
  className?: string;
  rainbow?: boolean;
  center?: boolean;
}

export const Heading: React.FC<React.PropsWithChildren<Readonly<HeadingProps>>> = ({
  text,
  size = 1,
  semanticSize,
  className,
  rainbow = false,
  center = false,
}) => {
  if (!semanticSize) {
    semanticSize = size;
  }
  const Element = `h${semanticSize}` as keyof JSX.IntrinsicElements;
  const fontSizes: string[] = [
    "text-3xl md:text-4xl lg:text-5xl",
    "text-2xl md:text-3xl lg:text-4xl",
    "text-xl md:text-2xl text-2xl",
    "text-lg md:text-xl",
  ];
  const weights: string[] = ["font-normal", "font-extrabold", "font-extrabold", "font-extrabold"];
  const classes = cx([
    "space-y-4",
    "font-light",
    "font-heading",
    "block",
    fontSizes[size - 1],
    weights[size - 1],
    className,
    {
      "text-center": center,
    },
  ]);
  const rainbowStyle = {
    backgroundImage: "linear-gradient(90deg, #305cea, #773de2 50%, #e95370)",
    display: "block",
    WebkitBackgroundClip: "text",
    backgroundClip: "text",
    WebkitTextFillColor: "transparent",
  };

  return (
    <Element className={classes}>
      {text?.split("\n").map((i) =>
        rainbow ? (
          <span key={`text-${i}`} style={rainbowStyle}>
            {i}
          </span>
        ) : (
          <span key={`text-${i}`} className="block">
            {i}
          </span>
        ),
      )}
    </Element>
  );
};
