import React from "react";

import cx from "classnames";

export interface LoaderProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  size?: "xs" | "sm" | "md" | "lg";
  color?: string;
}

const loaderSizeXs = "h-6 w-6";
const loaderSizeSm = "h-8 w-8";
const loaderSizeMd = "h-12 w-12";
const loaderSizeLg = "h-16 w-16";

export const Loader: React.FC<React.PropsWithChildren<Readonly<LoaderProps>>> = ({
  className,
  children,
  size = "sm",
  color = "text-od-blue",
  ...props
}) => (
  <div {...props} className={cx("flex items-center justify-center", className)}>
    <svg
      className={cx(
        "animate-spin",
        {
          [loaderSizeXs]: size === "xs",
          [loaderSizeSm]: size === "sm",
          [loaderSizeMd]: size === "md",
          [loaderSizeLg]: size === "lg",
        },
        color,
      )}
      xmlns="https://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
    <div className="text-gray-400">{children}</div>
  </div>
);
