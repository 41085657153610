import React, { MouseEvent } from "react";

import cx from "classnames";
import { CgCloseO } from "react-icons/cg";

interface CloseButtonProps {
  onClose: (e: MouseEvent<HTMLButtonElement>) => void;
}

export const CloseButton: React.FC<React.PropsWithChildren<Readonly<CloseButtonProps>>> = ({ onClose }) => {
  const classes = cx("focus:outline-none focus:ring-2 focus:ring-offset-2", "focus:ring-green-500 rounded-full");
  return (
    <div className="fixed top-0 right-0 z-30 p-4">
      <button onClick={onClose} className={classes}>
        <CgCloseO className="w-8 h-8" />
      </button>
    </div>
  );
};
